/* eslint-disable no-lone-blocks */
import { setup } from '@contentful/dam-app-base'

import { init, locations } from '@contentful/app-sdk'
import { Button, Notification } from '@contentful/f36-components'
import tokens from '@contentful/f36-tokens'
import { WorkflowsIcon } from '@contentful/f36-icons'
import { css } from 'emotion'

// import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client'

import './index.css'

const logoUrl = 'https://panel.storylink.us/logo.png'
const basePopupUrl = 'https://panel.storylink.us'

let selCount, selTypes

setup({
  cta: 'Add Assets',
  name: 'StoryLink',
  logo: logoUrl,
  color: '#036FE3',
  description: 'Configure StoryLink',
  parameterDefinitions: [
    {
      id: 'connectionKey',
      type: 'Symbol',
      name: 'Connection Key',
      description: 'Enter StoryLink Connection Key',
      required: true,
    },
    {
      id: 'selectionCount',
      type: 'Number',
      name: 'Selection Count',
      description: 'Numbers of assets that can be selected. [ Optional ]',
      default: 1,
      required: false,
    },
    {
      id: 'selectionTypes',
      type: 'Symbol',
      name: 'Selection Types',
      description: 'Type of assets that can be selected. [ Optional ]',
      default: 'Image, Video, Doc',
      required: false,
    },
  ],
  validateParameters,
  makeThumbnail,
  openDialog,
  customUpdateStateValue,
  renderDialog: () => {},
  isDisabled: () => false,
})

const showStoryLinkPopup = (sdk) => {
  // Check if popup Window is open
  if (window.slPopupWin) {
    window.slPopupWin.focus()
    return { alreadyOpen: true }
  }

  // Get Params .. Instance params will take precedense if provided
  let { connectionKey, selectionCount, selectionTypes, panelUrl, settingsUrl } = sdk.parameters.instance || {}
  {
    if (!connectionKey) connectionKey = sdk.parameters.installation.connectionKey

    if (!selectionCount) selectionCount = sdk.parameters.installation.selectionCount

    if (!selectionTypes) selectionTypes = sdk.parameters.installation.selectionTypes
    if (selectionTypes && !Array.isArray(selectionTypes)) {
      selectionTypes = selectionTypes
        .split(',')
        .filter((x) => x)
        .map((x) => `${x}`.trim().toLowerCase())
    }
  }

  // Set popup dimensions
  const w = 630,
    h = 850
  const left = 300
  const top = 100

  // Open popup
  window.slPopupWin = window.open(
    panelUrl || basePopupUrl,
    '_blank',
    `popup=yes,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=${w},height=${h},top=${top},left=${left}`,
  )

  // Set global props
  selCount = selectionCount || 5
  selTypes = selectionTypes || []

  return {
    connectionKey,
    selectionCount,
    selectionTypes,
    settingsUrl,
  }
}

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

async function openDialog(sdk, _currentValue, _config, isValidating) {
  const storyLinkInfo = showStoryLinkPopup(sdk)

  if (storyLinkInfo.alreadyOpen !== true) {
    const storyLinkPopupListener = async (event) => {
      const { type } = event.data

      if (type === 'to.plugin.info') {
        window.slPopupWin.postMessage({ type: 'from.plugin.info', storyLinkInfo }, '*')
      }

      if (type === 'to.plugin.ready') {
        const { appConfig } = event.data
        if (appConfig) window.storyLinkPopupConfigs = { ...appConfig }
      }

      if (type === 'to.plugin.app.closed') {
        window.removeEventListener('message', storyLinkPopupListener)
        if (window.slPopupWin) window.slPopupWin.close()
        window.slPopupWin = undefined
      }

      if (type === 'to.plugin.assets.place') {
        const { assets } = event.data

        if (!assets || !assets.length) {
          Notification.setPlacement('bottom', { offset: 0 })
          Notification.error(`No content was selected.`)
          return
        }

        // Add assets to the new field value
        let newFieldValue = []
        assets.reverse().forEach(async (asset) => {
          // check for selection type
          if (asset.previewType && !selTypes.includes(`${asset.previewType}`.toLowerCase())) {
            Notification.setPlacement('bottom', { offset: 0 })
            Notification.warning(`[ ${asset.filename} ] - Invalid Type [${asset.previewType}]`)
            return
          }
          newFieldValue.push(asset)
        })

        // Merge with current .. slice up to the selCount if given
        newFieldValue = [...newFieldValue, ...(sdk.field.getValue() || [])].slice(0, selCount > 0 ? selCount : undefined)

        // Set the new value
        await sdk.field.setValue(newFieldValue)
      }
    }
    window.removeEventListener('message', storyLinkPopupListener)
    window.addEventListener('message', storyLinkPopupListener)
  }

  return !isValidating ? _currentValue : { ...storyLinkInfo, alreadyOpen: storyLinkInfo.alreadyOpen === true }
}

async function validateAssets(sdk) {
  // Check if there is anthing to validate
  const currentAssets = sdk.field.getValue() || []
  if (!currentAssets.length) return

  // Sender method for validate request
  const sendValidateRequest = () => {
    if (window.slPopupWin) {
      verifyButtonRender(sdk, true)
      window.slPopupWin.postMessage({ type: 'from.plugin.assets.validate', versionIds: currentAssets.map((e) => e.versionId) }, '*')
    }
  }

  // Setup the listener for validation events
  const validationPopupListener = async (event) => {
    const { type } = event.data

    if (type === 'to.plugin.ready') {
      const { appConfig } = event.data
      if (appConfig) window.storyLinkPopupConfigs = { ...appConfig }
      sendValidateRequest()
    }

    if (type === `to.plugin.assets.validated`) {
      try {
        const { assets } = event.data
        if (!assets || !assets.length) {
          Notification.setPlacement('bottom', { offset: 0 })
          Notification.error(`No assets were provided.`)
        } else {
          const newFieldValue = assets.filter((asset) => asset.previewType && selTypes.includes(`${asset.previewType}`.toLowerCase())).slice(0, selCount > 0 ? selCount : undefined)
          await sdk.field.setValue(newFieldValue)
        }
      } finally {
        verifyButtonRender(sdk, false)
        window.removeEventListener('message', validationPopupListener)
      }
    }

    if (type === 'to.plugin.app.closed') {
      window.removeEventListener('message', validationPopupListener)
      if (window.slPopupWin) window.slPopupWin.close()
      window.slPopupWin = undefined
    }
  }
  window.removeEventListener('message', validationPopupListener)
  window.addEventListener('message', validationPopupListener)

  const { alreadyOpen } = await openDialog(sdk, null, null, true)
  if (alreadyOpen === true) sendValidateRequest()
}

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

async function customUpdateStateValue({ currentValue, result, config }, updateStateValue) {
  await updateStateValue(result)
}

function makeThumbnail(attachment) {
  const thumbnail = attachment.thumbUrl || attachment.previewUrl
  const url = typeof thumbnail === 'string' ? thumbnail : undefined
  const alt = attachment.filename
  return [url, alt]
}

function validateParameters({ connectionKey }) {
  if (!connectionKey) return 'Missing Connection Key'
  return null
}

function verifyButtonRender(sdk, validationFlag = false) {
  const verifyRoot = createRoot(document.getElementById('verifyRoot'))
  verifyRoot.render(
    <div className={styles.container}>
      <div style={{ width: 30 + 'px', heigth: 30 + 'px', marginRight: 16 + 'px' }}></div>
      <Button startIcon={<WorkflowsIcon />} variant='secondary' size='small' onClick={() => validateAssets(sdk)} isLoading={validationFlag}>
        Update to latest
      </Button>
    </div>
  )
}

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

const styles = {
  container: css({
    display: 'flex',
  }),
  logo: css({
    display: 'block',
    width: '30px',
    height: '30px',
    marginRight: tokens.spacingM,
  }),
}

// Remove popup if the app is unloaded
window.addEventListener('unload', (e) => window.slPopupWin && window.slPopupWin.close())

// Init Contentful SDK
init((sdk) => {
  if (sdk?.location.is(locations.LOCATION_ENTRY_FIELD)) verifyButtonRender(sdk)
  if (sdk?.window) sdk.window.startAutoResizer()
})
